import React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Grid, Picture, Margin, PageWrapper } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PressProps } from '@types'
import { type } from '@theme'


const PressPage = ({ data: { 
  sanityPage: {
    title,
    gridItems,
  },
  allSanityPress: {
   nodes
  }
}}: { data: PressProps }) => {
  return (
    <>
      <Helmet>
        <title>the buddy system: {title}</title>
      </Helmet>
      <PageWrapper>
        <Margin>
          <PressGrid>
            <PageTitle>{title}</PageTitle>
            {gridItems.filter(p => !!p).map((item) => (
              <GridItem key={item._key} {...item} />
            ))}
            {nodes.filter(p => !!p).map((item, i) => (
              <PressItem key={i}>
                <LinkMask href={item.link} target="_blank" />
                <ArticleData>
                  <Title className="linkHighlight" strlen={item.title.length} >{item.title}</Title>
                  <Publication >{item.publication} <span>{item.datePublished}</span></Publication>
                </ArticleData>
                <ImageWrapper>
                  <StyledPicture src={item.image.asset.url} dimensions={item.image.asset.metadata.dimensions} alt={item.publication} />
                </ImageWrapper>
              </PressItem>
            ))}
          </PressGrid>
        </Margin>
        <TransitionMask />
      </PageWrapper>
    </>
  )
}


export const PageTitle = styled.h1`
  grid-column-start: 1;
  grid-column-end: span 6;
  ${type.headline1}
`


const PressGrid = styled(props => <Grid {...props} />)`
  grid-row-gap: 50px;
`


const PressItem = styled(props => <Grid {...props} />)`
  border-top: 1px solid white;
  padding-top: 25px;
  position: relative;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 12;
  }  
`

const LinkMask = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:hover ~ article .linkHighlight {
    text-decoration-color: rgba(255, 255, 255, 0.5);
  }
`

const ImageWrapper = styled.div`
  display: block;
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: 9;
    grid-column-end: span 4;
  }
  img {
    border-radius: 10px;
  }
`

const ArticleData = styled.article`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {    
    grid-column-end: span 8;
  }
`

const Publication = styled.div`
  font-family: 'GT_Alpina', 'Georgia', serif;
  font-size: 2rem;
  span {
    margin-left: 20px;
    ${type.micro}
  }
  margin-bottom: 1em;
`
const Title = styled.h2<{ strlen: number }>`
  font-family: 'GT_Alpina', 'Georgia', serif;
  font-size: ${props => props.strlen <= 32 ? '3rem' : '2rem'};
  @media only screen and (min-width: 744px) {
    font-size: ${props => props.strlen <= 32 ? '8vw' : '3.5vw'};
    line-height: ${props => props.strlen <= 32 ? '1em' : '1.2em'};
  }
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: rgba(255, 255, 255, 0);
  text-underline-offset: 0.1em;
  transition: text-decoration-color 0.35s ease-in-out;
`


const StyledPicture = styled(props => <Picture {...props} />)`
  height: auto;
`



export const query = graphql`
  query PressPageQuery {
    sanityPage(slug: {current: {eq: "press"}}) {
      ...pageFields
    }
    allSanityPress {
      nodes {
        title
        publication
        datePublished(formatString: "MMM D YYYY")
        link
        image {
          asset {
            url
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default PressPage
